<template>

      <v-navigation-drawer
        v-model="drawer"
        app
        permanent
      >

        <v-list-item>
          
            <v-list-item-title class="text-h6">
              <v-row>
                <v-col cols="3" style="align-content: center;" class="pb-0">
                  
                    <v-img
                      :width="48"
                      aspect-ratio="1/1"
                      cover
                      src="../../assets/Logo.png"
                    ></v-img>
                  
                </v-col>
                <v-col cols="9" class="pb-0 mt-5">
                  <v-badge :color="bageData()['color']" :content="bageData()['content']">
                  <h1 class="site-title">Tradecity</h1></v-badge>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <h2 class="site-subtitle">Payment service</h2>
                </v-col>
              </v-row>
            </v-list-item-title>
          
        </v-list-item>
  

        <v-divider></v-divider>
        <v-list-item
            :subtitle="email"
            :title="user_name"
          >
            <template v-slot:append>
              <v-menu :location="location">
                <template v-slot:activator="{ props }">
                  <v-btn
                    icon="mdi-menu-down"
                    size="small"
                    variant="text"
                    v-bind="props"
                  ></v-btn>
                </template>

                <v-list>
                  <v-list-item
                    link
                    to="/settings"
                  >
                    <v-list-item-title><v-icon>mdi-cog</v-icon> Настройки</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    link
                    @click="logOut"
                  >
                    <v-list-item-title><v-icon>mdi-logout</v-icon> Выход</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-list-item>
  
        <v-divider></v-divider>

        <v-list-item v-if="role==='trader'">
          <v-switch
            color="warning"
            label="Принимаю ордера"
            hide-details
            inset
            v-model="working"
            @change="workingSwitch"
            class="order-switch"
          ></v-switch>
        </v-list-item>
        
        <v-divider></v-divider>
        <v-slide-group
          show-arrows
        >
          <v-slide-group-item
            v-for="rate, index in rates"
            :key="index"
          >
            <v-card color="#1F7087" width="80%" class="text-center">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="text-h5 text-center">
                    {{ rate.rate }}
                  </v-card-title>
                  <v-card-subtitle>{{ rate.pair }}</v-card-subtitle>
                </div>
              </div>
            </v-card>
          </v-slide-group-item>
        </v-slide-group>

        <v-divider></v-divider>

        <v-list dense>
            <v-list-subheader>Финансы</v-list-subheader>
            <v-list-item
                v-for="(item, index) in filteredMenuItems"
                :key="index"
                link
                :to="item.link"
                class="custom-surface"
            >
                <!-- <v-list-item-icon>
                </v-list-item-icon> -->
                <v-list-item-title><v-icon>{{ item.icon }}</v-icon> {{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
        <v-list dense>
            <v-list-subheader>Управление</v-list-subheader>
            <v-list-item
                v-for="(item, index) in filteredMenuItemsDefault"
                :key="index"
                link
                :to="item.link"
                class="custom-surface"
            >
                <!-- <v-list-item-icon>
                </v-list-item-icon> -->
                <v-list-item-title><v-icon>{{ item.icon }}</v-icon> {{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
      </v-navigation-drawer>
  </template>
  
  <script>
import apiClient from '@/plugins/axios'
import { mapState } from 'vuex';

export default {
    data: () => ({
        user_name: "test",
        email: "null",
        working: false,
        drawer: true,
        location: 'end',
        rates: [],
        bage: {
          color: "#FFFFFF",
          content: null
        },
        menuItems: [
          { title: 'Балансы', icon: 'mdi-wallet', link: "/" , roles: ['trader', 'merchant', 'operator']},
          { title: 'Вводы/Выводы', icon: 'mdi-cash', link: "/withdrawals", roles: ['trader', 'merchant'] },
          { title: 'Внутренние переводы', icon: 'mdi-bank-transfer', link: "/internal", roles: ['trader', 'merchant'] },
          { title: 'Изменения баланса', icon: 'mdi-finance', link: "/balancechange", roles: ['trader', 'merchant'] },
        ],
        menuItemsDefault: [
          { title: 'Реквизиты', icon: 'mdi-credit-card', link: "/details", roles: ['trader'] },
          { title: 'Ордера', icon: 'mdi-tray-plus', link: '/orders', roles: ['trader', 'merchant']},
          { title: 'Диспуты', icon: 'mdi-forum-outline', link: '/disputes', roles: ['trader', 'merchant', 'operator']},
          { title: 'Автоматизация', icon: 'mdi-brightness-auto', link: '/automation', roles: ['trader'] },
          { title: 'API', icon: 'mdi-api', link: '/api_merchant', roles: ['merchant'] },
          // { title: 'Реферальная система', icon: 'mdi-account-switch' , roles: ['trader', 'merchant']},
          { title: 'Статистика', icon: 'mdi-chart-areaspline', link: '/statistics', roles: ['trader', 'merchant', 'admin'] },
        ],
        intervalId: null,

    }),
    computed: {
      ...mapState({
        role: state => state.role
      }),
      filteredMenuItems() {
        return this.menuItems.filter(item => item.roles.includes(this.role));
      },
      filteredMenuItemsDefault() {
        return this.menuItemsDefault.filter(item => item.roles.includes(this.role));
      },
    },
    methods: {
      getUserInfo() {
        apiClient.get('user/info/')
            .then(response => {
                this.user_name = response.data.user_name
                this.email = response.data.email
                this.working = response.data.working
            })
            .catch(error => {
                console.error(error);
            });
      },
      workingSwitch() {
        apiClient.get('user/working/')
            .then(response => {
                this.working = response.data.working
            })
            .catch(error => {
                console.error(error);
            });
      },
      bageData() {
        let color = null;
        let content = null;
        switch (this.$store.state.role) {
          case 'trader':
            this.bage.color = "green"
            this.bage.content = "Трэйдер"
            break;
          case 'merchant':
            this.bage.color = "orange"
            this.bage.content = "Мерчант"
            break;
          case 'operator':
            this.bage.color = "blue"
            this.bage.content = "Персонал"
            break;
          case 'admin':
            this.bage.color = "red"
            this.bage.content = "Админ"
            break;
          
        }
        color = this.bage.color
        content = this.bage.content
        return {color: color, content: content}
      },
      gerRate() {
        apiClient.get('catalogs/rate/')
            .then(response => {
                this.rates = response.data
            })
            .catch(error => {
                console.error(error);
            });
      },
      async fetchData() {
        try {
          const response = await apiClient.get('catalogs/rate/');
          this.rates = response.data;
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      },
      startFetching() {
        this.fetchData(); 
        this.intervalId = setInterval(this.fetchData, 5000); 
      },
      stopFetching() {
        if (this.intervalId) {
          clearInterval(this.intervalId); 
          this.intervalId = null;
        }
      }
    },
    created() {
      this.getUserInfo()
      console.log("ROLE", this.$route.meta.role)
      this.bageData()
    },
    watch: {
      bage() {
        if (!this.bage.content) {
          this.bageData()
        }
      }
    },
    mounted() {
      this.startFetching();
      this.bageData()
    },
    beforeUnmount() {
      this.stopFetching(); 
    }
  };
  </script>
  
  <style scoped>
  .v-navigation-drawer {
    height: 100vh;
  }
  .site-title {
    font-size: 1.25em;
    color: #5a62e1;
  }
  .site-subtitle {
    color: gray;
    font-size: 0.75em;
    font-weight: 400;
    /* margin-left: 20%; */
  }
  .order-switch {
    color: #ffffff;
  }
  .just-cent div div{
    justify-content: center;
  }
  </style>
  