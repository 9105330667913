<template>
  <v-container fluid>
    <div class="text-h6">Api & Webhook settings</div>
    <v-divider></v-divider>
    <v-alert color="warning" icon="$info" title="Api" class="ma-2"></v-alert>
    <v-row no-gutters>
      <v-col cols="12">
        <v-sheet
          class="d-flex align-center flex-wrap justify-space-between pa-4 pb-2 mb-2"
          style="border-radius: 4px"
        >
          <h2 class="text-h5 ma-0 mb-2">
            Ваш апи ключ -
            <span @click="copyToClipboard()" class="key">{{ api_key }}</span>
            <v-icon
              @click="copyToClipboard()"
              style="box-shadow: none; font-size: 20px"
              >mdi-content-copy</v-icon
            >
          </h2>
          <v-btn
            @click="openWarningDialog()"
            color="white"
            class="text-none mb-2"
            style="box-shadow: none"
            >Обновить</v-btn
          >
        </v-sheet>
        <v-btn color="primary" class="text-none" style="box-shadow: none">
          <a class="text-decoration-none" style="color: white" :href="'/api-docs/' + api_key" target="_blank"
            >Открыть документацию</a
          >
        </v-btn>
        <v-btn color="primary" class="text-none ml-2" style="box-shadow: none">
          <a class="text-decoration-none" style="color: white" :href="this.$store.state.base_url + 'merchant/api_docs/postman' + '?api_key=' + api_key" target="_blank"
            >Скачать Postaman коллекцию</a
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Webhook URL"
          v-model="webhook_url"
          outlined
          dense
          clearable
          placeholder="https://example.com/webhook"></v-text-field>
          <v-btn color="green" class="text-none ml-2" style="box-shadow: none" @click="updateWebhookUrl">
            Сохранить
          </v-btn>
      </v-col>
    </v-row>
    <successfull-copy-dialog
      :error="copyError"
      :isCopied="isCopied"
      :open="copyDialog"
      @close-dialog="closeCopyDialog()"
    />
    <v-dialog
      transition="dialog-top-transition"
      width="400"
      v-model="warningDialog"
    >
      <v-card color="white">
        <v-toolbar title="Предупреждение" color="primary"></v-toolbar>

        <v-card-text
          >Вы уверены, что хотите сменить api key?</v-card-text
        >
        <v-card-actions class="justify-end">
          <v-btn
            class="ms-auto"
            color="warning"
            outline
            @click="warningDialog = false"
            >Отмена</v-btn
          >
          <v-btn class="ml-0" color="primary" outline @click="chageApiKey">Принять</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SuccessfullCopyDialog from "@/components/dialogs/SuccessfullCopyDialog.vue";
import { mapState } from "vuex";

export default {
  components: {
    SuccessfullCopyDialog,
  },
  data: () => ({
    copyError: false,
    warningDialog: false,
    isCopied: null,
    copyDialog: false,
    reward: 0,
    api_key: "56324573thfdghwerty54",
    webhook_url: "",
  }),
  methods: {
    openWarningDialog() {
      this.warningDialog = true;
    },
    copyToClipboard() {
      this.copyError = false;
      this.isCopied = this.api_key;

      this.copyDialog = true;

      navigator.clipboard
        .writeText(this.api_key)
        .then(() => {
          console.log("Api key успешно скопирован в буфер обмена!");
        })
        .catch((err) => {
          this.copyError = true;
          console.error("Ошибка при копировании в буфер обмена: ", err);
        });
    },
    closeCopyDialog() {
      this.copyDialog = false;
    },
    getMerchData() {
      this.getDataList('merchant/data/').then(data => {
                console.log("merchant. Data: ", data);
                this.api_key = data.api_key;
                this.webhook_url = data.url;
            });
    },
    chageApiKey() {
      this.getDataList('merchant/api_key_refresh/').then(data => {
                console.log("merchant. Data: ", data);
                this.api_key = data.api_key;
                this.warningDialog = false;
        });
    },
    updateWebhookUrl() {
      this.saveData('merchant/webhook/', {url: this.webhook_url}).then(data => {
            console.log("merchant. DataUPDATE: ", data);
            this.webhook_url = data.url;
        });
    },
  },
  created() {},
  mounted() {
    // Инициализируем подсветку после того, как компонент был смонтирован
    // this.$nextTick(() => {
    //     document.querySelectorAll('pre code').forEach((block) => {
    //         hljs.highlightElement(block);
    //     });
    //     });
    this.getMerchData()
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
};
</script>

<style scoped>
.key {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  padding: 0 8px;
  cursor: pointer;
  transition: background-color 0.2s linear;
  margin-right: 4px;
}

.key:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
</style>
