import { createRouter, createWebHistory } from 'vue-router'
import BalanceView from '@/views/BalanceView.vue'
import WithdrawalView from '@/views/WithdrawalView.vue'
import InternalTrView from '@/views/InternalTrView.vue'
import BalanceChangeView from '@/views/BalanceChangeView.vue'
import MobileFinanceView from '@/views/MobileFinanceView.vue'
import MobileControlView from '@/views/MobileControlView.vue'
import StatisticsView from '@/views/StatisticsView.vue'
import SettingsView from '@/views/SettingsView.vue'
import LoginView from '@/views/LoginView.vue'
import { checkAuth } from '@/api/auth'
import DetailsView from '@/views/DetailsView.vue'
import OrdersView from '@/views/OrdersView.vue'
import MerchantTest from '@/views/MerchantTest.vue'
import AutomationView from '@/views/AutomationView.vue'
import store from '@/store';
import MerchantApi from '@/views/MerchantApiView.vue'
import DisputesView from '@/views/DisputesView.vue'
import DocsApiView from '@/views/DocsApiView.vue'

const routes = [
  {   
    path: '/index.html',
    component: BalanceView,
    meta: { requiresAuth: true, roles: ['trader', 'merchant', 'operator'], role: null },
    alias: '/'
  },
  {
    path: '/',
    name: 'home',
    component: BalanceView, 
    meta: { requiresAuth: true, roles: ['trader', 'merchant', 'operator'], role: null }
    
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView, 
  },
  {
    path: '/withdrawals',
    name: 'withdrawals',
    component: WithdrawalView, 
    meta: { requiresAuth: true, roles: ['trader', 'merchant'], role: null }
  },
  {
    path: '/internal',
    name: 'internal',
    component: InternalTrView, 
    meta: { requiresAuth: true, roles: ['trader', 'merchant'], role: null }
  },
  {
    path: '/balancechange',
    name: 'balancechange',
    component: BalanceChangeView, 
    meta: { requiresAuth: true, roles: ['trader', 'merchant'], role: null }
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView, 
    meta: { requiresAuth: true, roles: ['trader', 'merchant'], role: null }
  },
  {
    path: '/automation',
    name: 'automation',
    component: AutomationView,
    meta: { requiresAuth: true, roles: ['trader'], role: null },
  },
  {
    path: '/api_merchant',
    name: 'api_merchant',
    component: MerchantApi,
    meta: { requiresAuth: true, roles: ['merchant'], role: null },
  },
  {
    path: '/disputes',
    name: 'disputes',
    component: DisputesView, 
    meta: { requiresAuth: true, roles: ['trader', 'merchant', 'operator'], role: null }
  },
  {
    path: '/details',
    name: 'details',
    component: DetailsView, 
    meta: { requiresAuth: true, roles: ['trader'], role: null }
  },
  {
    path: '/finance',
    name: 'finance',
    component: MobileFinanceView, 
    meta: { requiresAuth: true }
  },
  {
    path: '/control',
    name: 'control',
    component: MobileControlView, 
    meta: { requiresAuth: true }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: StatisticsView, 
    meta: { requiresAuth: true, roles: ['trader', 'merchant'], role: null }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView, 
    meta: { requiresAuth: true, roles: ['trader', 'merchant'], role: null }
  },
  {
    path: '/merchant-test',
    name: 'merchant-test',
    component: MerchantTest, 
    meta: { requiresAuth: false, roles: ['trader', 'merchant'], role: null }
  },
  {
    path: '/api-docs/:api_key',
    name: 'merchant-api-docs',
    component: DocsApiView, 
    meta: { requiresAuth: false, roles: ['merchant'], role: null },
    props: true 
  },
  // {
  //   path: '*',
  //   redirect: '/login',
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const userRole = await checkAuth();

    if (!userRole) {
      return next('/login');
    }
    store.dispatch('saveUserRole', userRole);
    to.meta.role = userRole;
    if (to.meta.roles && to.meta.roles.includes(userRole)) {
      next(); 
    } else {
      next('/login');
    }
  } else {
    next(); 
  }
});

export default router
