<template>
    <openapi-explorer
      :spec-url="spec"
      hide-authentication
    >
    </openapi-explorer>
</template>
  
<script>
import 'openapi-explorer';

export default {
    props: {
        api_key: {
            type: String,
            required: true
        }
    },
  data: () => ({
    spec: null,
  }),
  mounted() {
    // Убедитесь, что веб-компонент загружен
    if (!customElements.get('openapi-explorer')) {
      console.error('openapi-explorer не зарегистрирован.');
    }
    this.spec = this.$store.state.base_url + "merchant/api_docs/json" + "?api_key=" + this.api_key;
  },
};
</script>