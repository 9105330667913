import { createStore } from 'vuex'

export default createStore({
  state: {
    role: null,
    timerForGetMessage: 5000,
    // base_url: "http://127.0.0.1:8002/api/v1/",
    base_url: "https://tradecity.io/api/v1/",
  },
  mutations: {
    setRole(state, role) {
      state.role = role;
    }
  },
  actions: {
    saveUserRole({ commit }, role) {
      commit('setRole', role);
    }
  },
  modules: {
  }
})
